module.exports = [{
      plugin: require('../node_modules/gatsby-source-wordpress/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"type":{"MediaItem":{"lazyNodes":true,"localFile":{"maxFileSizeBytes":1,"excludeByMimeTypes":[],"requestConcurrency":100},"createFileNodes":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"html":{"createStaticFiles":false,"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":100,"imageQuality":90,"generateWebpImages":false},"schema":{"requestConcurrency":1,"timeout":60000,"perPage":20,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"url":"https://icy-moon.devular.cloud/wp/index.php?graphql","verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":[],"urls":["/fonts/auto-fonts.css","/fonts/manual-fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"aqi-org-uk","short_name":"aqi-org-uk","start_url":"/","background_color":"#24252d","theme_color":"#24252d","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4635f04944ae639180ac5eb38a097755"},
    },{
      plugin: require('../node_modules/@devular/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"aqi.org.uk","proxyScript":"/app-scripts/js/script.js","proxyApi":"/app-scripts/js/core"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
