// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-blogs-wp-post-slug-js": () => import("./../../../src/pages/blogs/{WpPost.slug}.js" /* webpackChunkName: "component---src-pages-blogs-wp-post-slug-js" */),
  "component---src-pages-briefings-wp-briefing-slug-js": () => import("./../../../src/pages/briefings/{WpBriefing.slug}.js" /* webpackChunkName: "component---src-pages-briefings-wp-briefing-slug-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-data-stories-js": () => import("./../../../src/pages/data-stories.js" /* webpackChunkName: "component---src-pages-data-stories-js" */),
  "component---src-pages-data-stories-wp-data-category-slug-js": () => import("./../../../src/pages/data-stories/{WpDataCategory.slug}.js" /* webpackChunkName: "component---src-pages-data-stories-wp-data-category-slug-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-publications-wp-publication-slug-js": () => import("./../../../src/pages/publications/{WpPublication.slug}.js" /* webpackChunkName: "component---src-pages-publications-wp-publication-slug-js" */),
  "component---src-pages-timelines-js": () => import("./../../../src/pages/timelines.js" /* webpackChunkName: "component---src-pages-timelines-js" */),
  "component---src-pages-timelines-wp-timeline-slug-js": () => import("./../../../src/pages/timelines/{WpTimeline.slug}.js" /* webpackChunkName: "component---src-pages-timelines-wp-timeline-slug-js" */)
}

